
<template>
  <div>
    <apexchart
      width="500"
      type="donut"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>
    <p></p>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { useLoadGroup } from "@/firebase";
import { watchEffect } from "vue";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    let groups = useLoadGroup();
    console.log("dd", groups.value)
    let arr = [];
    watchEffect(() => {
      groups.value.forEach(element => {
        console.log(element.name)
        arr.push(element.name)
      });
    })
    console.log("412", arr)
    return { groups, arr };
  },
  data() {
    return {
      chartOptions: {
        chart: {},
        labels: ['Ikea', 'ICA', 'Willys'],
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
        },
      },
      series: [11, 21, 3],
    }
  },
  methods: {

  },
  computed: {

  }
};
</script>
