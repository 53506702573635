
<template>
  <div>
    <apexchart width="500" type="bar" :options="chartOptions" :series="series"></apexchart>
    <p></p>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
    data: function() {
      return {
        chartOptions: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: ["Stolar", "Bord", "Glass", "Bröd"]
          }
        },
        series: [{
          name: 'series-1',
          data: [30, 40, 45, 50]
        }]
      }
    },
};
</script>
