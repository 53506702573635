<template>
<div>
    <NavBar />
  <div class="row p-4">
    <div class="col md-4">
      <DonutComponent />
    </div>
    <div class="col md-4">
      <Barcomponent />
    </div>
    
    <div v-if="isLoaded === true && isLoading === true" class="col md-4">
      <label for="formFile">Ett artikelregister har hittats!</label><br>
      <button type="button" class="btn btn-primary" @click="onFileRemove" >Ta bort</button>
    </div>
    <div v-else class="col md-4">
      <input class="form-control" type="file" id="formFile" @change="onFileChange">
    </div>
  </div>
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import DonutComponent from "@/components/statistics/DonutComponent.vue";
import Barcomponent from "@/components/statistics/Barcomponent.vue";

import { uploadArticleData, existsArticleData, removeArticleData} from "@/firebase";

export default {
  name: "Statistics",
  components: {
    NavBar,
    DonutComponent,
    Barcomponent
  },
  setup() {

    const articleUploaded = existsArticleData();


    return { removeArticleData, articleUploaded}
  },
  data() {
    return {
      isLoaded: true,
    }
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      console.log(xlsxfile)
      uploadArticleData(xlsxfile);
      this.isLoading = true;
    },
    onFileRemove() {
      this.removeArticleData();
      this.isLoaded = false;
    },
  },
  computed: {
    isLoading: {
      get() {
        console.log(this.articleUploaded)
        return (this.articleUploaded === 1) ? true : false;
      },
      set(isLoading) {
        this.isLoaded = isLoading
      }
    }
  }
}
</script>


<style scoped>
.storeLabel {
  color: black;
  font-size: 9pt;
  position: relative;
  background: white;
  border: 1pt solid blue;
  border-radius: 3px;
  top: -20px;
  right: -5px;
}
.productLabel {
  padding-left: 40px;
}

</style>
